import React, { FC, HTMLAttributes } from 'react';
//@ts-ignore
import styles from './ScrollDownAnimatedArrow.module.scss?module';

type ScrollDownAnimatedArrowPropT = HTMLAttributes<HTMLDivElement>;

const ScrollDownAnimatedArrow: FC<ScrollDownAnimatedArrowPropT> = ({
  ...props
}) => {
  return (
    <div {...props} className={styles.ScrollDownAnimationCotainer}>
      <div className={`${styles.ScrolldownArrows}`}>
        <div
          className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow1}`}
        ></div>
        <div
          className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow2}`}
        ></div>
        <div
          className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow3}`}
        ></div>
      </div>
    </div>
  );
};

export default ScrollDownAnimatedArrow;
