import { useState, useEffect } from "react";

const breakpointsMap = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type BreakpointsState = Record<BreakpointKey, boolean>;

const useViewports = () => {
  const [currentBreakpoints, setCurrentBreakpoints] = useState<BreakpointsState>({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  });

  function checkUserScreen() {
    const width = window.innerWidth;

    // Aktualisiere das Breakpoint-Objekt basierend auf der aktuellen Breite
    const updatedBreakpoints: BreakpointsState = {
      xs: width >= breakpointsMap.xs && width < breakpointsMap.sm,
      sm: width >= breakpointsMap.sm && width < breakpointsMap.md,
      md: width >= breakpointsMap.md && width < breakpointsMap.lg,
      lg: width >= breakpointsMap.lg && width < breakpointsMap.xl,
      xl: width >= breakpointsMap.xl && width < breakpointsMap.xxl,
      xxl: width >= breakpointsMap.xxl,
    };

    setCurrentBreakpoints(updatedBreakpoints);
  }

  useEffect(() => {
    checkUserScreen(); // Initialisiere bei der ersten Ausführung
    window.addEventListener('resize', checkUserScreen); // Hinzufügen des Event Listeners

    return () => {
      window.removeEventListener('resize', checkUserScreen); // Entfernen des Listeners bei Cleanup
    };
  }, []);

  return currentBreakpoints;
};

export default useViewports;
