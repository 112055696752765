import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { matchMultiplePaths } from '../../Helper/matchMultiplePaths';
import FixedContainer from './Abstracts/FixedContainer/FixedContainer';
import NavIcons from './Abstracts/NavIcons/NavIcons';
import Main from './Main/Main';
import TopRightContents from './TopRightContents/TopRightContents';
//@ts-ignore
import style from './VirtualTradeShow.module.scss?module';
import { getRequest } from '../../Helper/ApiClient';
import { VtsInterface } from '../../../Interface/Vts';
import LogoImage from './Abstracts/LogoImage/LogoImage';
import GuidedTour from './Main/GuidedTour/GuidedTour';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  showGuidedTourConsentCardAtom,
  startGuidedTourAtom,
} from './Atoms/vtsAtoms';

export const VtsContext = React.createContext<{
  vts: VtsInterface | undefined;
}>({
  vts: undefined,
});

const VirtualTradeShow = () => {
  const isStartedGuidedTour = useAtomValue(startGuidedTourAtom);
  const matchAdminPath = matchMultiplePaths(['/virtual-trade-show/admin/*']);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const vtsRequest = getRequest<VtsInterface>(
    '/api/onlineshop/vts' +
      (searchParams.toString().length > 0 ? '?' + searchParams.toString() : '')
  );
  const setShowGuidedTourConsentCard = useSetAtom(
    showGuidedTourConsentCardAtom
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=d032c482-d54f-4f25-9ee8-e438acac56a1';
    script.async = true;
    script.id = 'ze-snippet';
    document.body.appendChild(script);

    // Clean up the script element when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== '/virtual-trade-show/lobby') {
      return setShowGuidedTourConsentCard(false);
    }
  }, [location.pathname]);

  return (
    <div
      className="vts"
      style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}
    >
      <VtsContext.Provider value={{ vts: vtsRequest.data }}>
        {!isStartedGuidedTour ? (
          <>
            <Main />
            {!matchAdminPath && (
              <>
                <FixedContainer position={'topLeft'}>
                  <LogoImage />
                </FixedContainer>
                <FixedContainer position={'topRight'}>
                  <TopRightContents />
                </FixedContainer>
                <FixedContainer position={'bottomCenter'}>
                  <NavIcons />
                </FixedContainer>
                {guidedTourPosition()}
              </>
            )}
          </>
        ) : (
          <>{!matchAdminPath && guidedTourPosition()}</>
        )}
      </VtsContext.Provider>
    </div>
  );
};
export default VirtualTradeShow;

const guidedTourPosition = () => {
  return (
    <FixedContainer
      className={style.GuidedTourContainer}
      position="bottomCenter"
    >
      <GuidedTour />
    </FixedContainer>
  );
};
