import React, { useEffect, useRef } from 'react';

//@ts-ignore
import styles from './BrandPagesMobile.module.scss?module';
import { Col, Row, Container } from 'react-bootstrap';
import { INewComerWeekBrands } from '../../../../../../Interface/Ncw';
import Paginator from '../../../Paginator/Paginator';
import { useSliderGesture } from '../../../../../Hooks/useSliderGesture';
// import SnapSection from '../../../Abstracts/SnapSection/SnapSection';
import NcwBrandCategories from '../components/BrandProductList/NcwBrandCategories/NcwBrandCategories';
import BrandPresentation from '../components/BrandPresentation/BrandPresentation';
import {
  scrollToTop,
  ScrollToTopWrapper,
} from '../../../Abstracts/ScrollToTopWrapper/ScrollToTopWrapper';
import { NcwIsElementNotSwipingAtom } from '../../../Atoms/NcwAtoms';
import { useSetAtom } from 'jotai';
import DiscountLabel from '../../../Abstracts/DiscountLabel/DiscountLabel';
import ScrollDownAnimatedArrow from '../../../Abstracts/ScrollDownAnimatedArrow/ScrollDownAnimatedArrow';

type BrandPagesMobileProps = {
  ncwBrands: INewComerWeekBrands[];
  activeBrand: number;
  slideToPrevBrand: () => void;
  slideToNextBrand: () => void;
};

const BrandPagesMobile = ({
  ncwBrands,
  activeBrand,
  slideToPrevBrand,
  slideToNextBrand,
}: BrandPagesMobileProps) => {
  const setElementNotSwiping = useSetAtom(NcwIsElementNotSwipingAtom);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const sliderRef = useSliderGesture({
    onSwipeLeft: slideToPrevBrand,
    onSwipeRight: slideToNextBrand,
  });

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, ncwBrands.length);

    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.currentTime = 0;
        if (index === activeBrand) {
          video.play();
        }
      }
    });
  }, [activeBrand, ncwBrands]);

  useEffect(() => {
    scrollToTop(setElementNotSwiping);
  }, []);

  return (
    <>
      <div
        style={{
          overscrollBehavior: 'none',
          maxWidth: '100vw',
          contain: 'paint',
        }}
      >
        <div
          className="d-flex"
          style={{
            transform: `translate3d(-${activeBrand}00vw, 0,0)`,
            transition: 'all 700ms ease-in-out',
          }}
        >
          {ncwBrands.map((brand, index) => {
            const isActiveBrand = activeBrand === index;
            return (
              <Container
                key={brand.erp_id}
                fluid
                style={{
                  minWidth: '100vw',
                  height: isActiveBrand ? '' : '1px',
                  opacity: isActiveBrand ? 1 : 0.4,
                  transition: 'opacity 700ms ease-in-out',
                  backgroundColor: brand.colorBackground
                    ? brand.colorBackground
                    : undefined,
                  color: brand.colorText ? brand.colorText : undefined,
                  zIndex: 0,
                }}
                className="d-flex flex-column"
              >
                <ScrollToTopWrapper containerRef={containerRef}>
                  <Row
                    style={{
                      display: 'flex',
                      minHeight: '95dvh',
                      scrollSnapAlign: 'start',
                      scrollSnapStop: 'always',
                      padding: '0px',
                    }}
                  >
                    <div
                      className={styles.VideoContainer}
                      style={{
                        minHeight: 'calc(112px - 100vh)',
                        zIndex: 1,
                        padding: '0px',
                        backgroundImage: `url(${brand.brand_image_url})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      ref={isActiveBrand ? sliderRef : null}
                    >
                      <DiscountLabel
                        discountPromotion={brand.discountPromotion}
                      />
                      <video
                        ref={(element) => (videoRefs.current[index] = element)}
                        className={styles.BackgroundVideo}
                        src={isActiveBrand ? brand.brand_video_url : undefined}
                        autoPlay={isActiveBrand}
                        controls={false}
                        muted
                        loop
                        playsInline
                      />
                      <ScrollDownAnimatedArrow />
                    </div>
                  </Row>
                  <Row
                    style={{
                      minHeight: '100vh',
                      scrollSnapStop: 'always',
                      scrollSnapAlign: 'start',
                    }}
                  >
                    <Col>
                      <BrandPresentation ncwBrand={brand} />
                      <NcwBrandCategories
                        activeBrand={isActiveBrand}
                        ncwBrand={brand}
                      />
                    </Col>
                  </Row>

                  {/* </SnapSection> */}

                  <Row
                    style={{
                      minHeight: '56px',
                      scrollSnapAlign: 'start',
                      // scrollSnapStop: 'always',
                    }}
                  ></Row>
                </ScrollToTopWrapper>
              </Container>
            );
          })}
        </div>
      </div>
      <Paginator
        requestData={ncwBrands}
        slideToNextItem={slideToNextBrand}
        slideToPrevItem={slideToPrevBrand}
        currentSlideNum={activeBrand}
      />
    </>
  );
};

export default BrandPagesMobile;
