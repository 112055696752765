import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
// @ts-ignore
import style from './NavIcon.module.scss?module';

const NavIcon = (props: {
  exact?: boolean;
  link: string;
  icon: any;
  text: string;
  b2b?: boolean;
  onClick?: () => unknown;
}) => {
  const NavIcon = (
    <div className={style.NavIcon}>
      <div className={style.Icon}>
        <FontAwesomeIcon icon={props.icon} />
      </div>
      <div className={style.Text}>{props.text}</div>
    </div>
  );

  return (
    <NavLink
      onClick={props.onClick}
      end={props.exact}
      to={props.link}
      className={({ isActive }) =>
        [
          style.NavLink,
          style.NavLinkDisabled,
          isActive ? style.active : undefined,
        ].join(' ')
      }
    >
      {NavIcon}
    </NavLink>
  );
};

export default NavIcon;
