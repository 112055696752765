import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import Button from '../../../../Abstracts/Button/Button';
import FixedContainer from '../../Abstracts/FixedContainer/FixedContainer';
import {
  showGuidedTourConsentCardAtom,
  startGuidedTourAtom,
  currentGuidedTourStepAtom,
} from '../../Atoms/vtsAtoms';
import { Stepper, Step } from 'react-form-stepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleArrowRight,
  faCircleArrowLeft,
  faArrowLeftFromBracket,
} from '@fortawesome/pro-light-svg-icons';
import { useHandleLeaveGuidedTour } from '../../hooks/useHandleLeaveGuidedTour';
import { Image } from 'react-bootstrap';
//@ts-ignore
import styles from './GuidedTour.module.scss?module';
import useTypewriter from '../../../../Hooks/useTypewriter';

const GuidedTour = () => {
  const startGuidedTour = useAtomValue(startGuidedTourAtom);
  const showGuidedTourConsentCard = useAtomValue(showGuidedTourConsentCardAtom);
  return (
    <>
      {showGuidedTourConsentCard && !startGuidedTour && (
        <GuidedTourConsentCard />
      )}
      {startGuidedTour && <GuidedTourSteps />}
    </>
  );
};

export default GuidedTour;

const GuidedTourSteps = () => {
  const [currentStep, setCurrentStep] = useAtom(currentGuidedTourStepAtom);
  const { stopGuidedTour } = useHandleLeaveGuidedTour();
  const typedText = useTypewriter(GuidedTourStepsData[currentStep].content);

  return (
    <>
      {createPortal(
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            zIndex: '-1',
            backgroundImage: `url(${GuidedTourStepsData[currentStep].image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />,
        document.body
      )}
      <StepsControllBar />
      <SpeechBubbleCard>
        <span>{typedText}</span>
        <div className="d-flex justify-content-center gap-4">
          {currentStep !== 0 && (
            <span
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon size={'xl'} icon={faCircleArrowLeft} />
            </span>
          )}

          <span
            onClick={() => {
              if (GuidedTourStepsData.length - 1 > currentStep) {
                setCurrentStep(currentStep + 1);
              } else {
                stopGuidedTour();
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon size={'xl'} icon={faCircleArrowRight} />
          </span>
        </div>
      </SpeechBubbleCard>
    </>
  );
};

const GuidedTourConsentCard = () => {
  const setShowGuidedTourConsentCard = useSetAtom(
    showGuidedTourConsentCardAtom
  );
  const setStartGuidedTour = useSetAtom(startGuidedTourAtom);
  const [typedText, isEnd] = useTypewriter(
    "Welcome to the Virtual Trade Show! I'm NaskorBot, your virtual tour guide. Would you like to start a guided tour? I'll show you the key highlights and important stops. Just click on the button below to begin. If you'd prefer to skip the tour, you can click the exit now. Once the tour begins, you can leave it at any time. to begin."
  );

  return (
    <SpeechBubbleCard>
      <div style={{ padding: '10px' }}>
        <span>{typedText}</span>
        {isEnd && (
          <span
            onClick={() => {
              setStartGuidedTour(true);
              setShowGuidedTourConsentCard(false);
            }}
            className={styles.SpeechBubbleText}
          >
            <FontAwesomeIcon size={'xl'} icon={faCircleArrowRight} />
          </span>
        )}
      </div>

      {/* <span className="fs-5 fw-bold">
        Hi, I'm NaskorBot! Do
        <br />
        you want to start the
        <br />
        Guided Tour?
      </span>
      <Button
        className="rounded-3"
        onClick={() => {
          setStartGuidedTour(true);
          setShowGuidedTourConsentCard(false);
        }}
      >
        Yes
      </Button>
      <Button
        className="rounded-3"
        onClick={() => setShowGuidedTourConsentCard(false)}
      >
        No
      </Button> */}
    </SpeechBubbleCard>
  );
};

interface SpeechBubbleCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: ReactNode;
}

const SpeechBubbleCard: FC<SpeechBubbleCardProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <div className={`${styles.GuidedTourContainer} ${className}`} {...rest}>
      {children}

      <div style={{ position: 'absolute', bottom: '-450px', right: '-50px' }}>
        <Image
          src={
            'https://naskorsports01.ams3.cdn.digitaloceanspaces.com/assets/shop/vts/vts-2024-robot-tiny.png'
          }
          className="w-100"
          style={{ maxWidth: '200px' }}
          alt=""
        />
      </div>
    </div>
  );
};

const StepsControllBar = () => {
  const { stopGuidedTour } = useHandleLeaveGuidedTour();
  const [currentStep, setCurrentStep] = useAtom(currentGuidedTourStepAtom);

  return createPortal(
    <FixedContainer
      position="topCenter"
      style={{
        top: '75px',
        width: '45%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        className="rounded-5 text-white"
        onClick={() => {
          stopGuidedTour();
        }}
      >
        <span className="me-1">
          <FontAwesomeIcon icon={faArrowLeftFromBracket} />
        </span>
        {/* Leave Guded Tour */}
      </Button>
      <div className="flex-grow-1">
        <Stepper activeStep={currentStep}>
          {GuidedTourStepsData.map((step) => {
            return (
              <Step
                //@ts-ignore
                styleConfig={{
                  activeBgColor: '#254a91',
                  completedBgColor: '#233c6a',
                }}
                key={step.stepLabel}
                className={styles.Step}
                // label={step.stepLabel}
                label={''}
              />
            );
          })}
        </Stepper>
      </div>
    </FixedContainer>,
    document.body
  );
};

const GuidedTourStepsData = [
  // {
  //   image:
  //     'https://files.naskorsports.com/shop/vts/guided-tour-images/pos-1.png',
  //   // url: '/virtual-trade-show/exhibition/2/1',
  //   // stepId: '2',
  //   // viewCorner: '1',
  //   stepLabel: '1',
  //   content: (
  //     <>
  //       Welcome to the Virtual Trade Show! I'm NaskorBot, your virtual tour
  //       guide. Would you like to start a guided tour? I'll show you the key
  //       highlights and important stops. Just Click{' '}
  //       <FontAwesomeIcon icon={faCircleArrowRight} /> to begin.
  //     </>
  //   ),
  // },
  {
    image:
      'https://files.naskorsports.com/shop/vts/guided-tour-images/pos-2.png',
    // url: '/virtual-trade-show/exhibition/3/2',
    stepLabel: '1',
    content:
      "Welcome to the main hall of the Virtual Trade Show! Let me explain how to navigate: You'll see navigation points on the floor—click on them to move around the venue. If you'd like to explore a booth, simply click on it to visit and access detailed information",
  },
  {
    image:
      'https://files.naskorsports.com/shop/vts/guided-tour-images/pos-3.png',
    // url: '/virtual-trade-show/exhibition/4/3',
    stepLabel: '2',
    content: 'premium Parter Text',
  },
  {
    image:
      'https://files.naskorsports.com/shop/vts/guided-tour-images/pos-4.png',
    // url: '/virtual-trade-show/exhibition/5/4',
    stepLabel: '3',
    content:
      "The Showcase Area is our biggest highlight this year. Here, you'll find the product highlights from all of our Premium Brands. Click on the display cases to explore everything in detail and learn more about the different products.",
  },
  {
    image:
      'https://files.naskorsports.com/shop/vts/guided-tour-images/pos-5.png',
    // url: '/virtual-trade-show/exhibition/8/2',
    stepLabel: '4',
    content:
      "In December, our customers voted for their favorites across 17 categories. The winners of last year's survey represent the best products in their respective categories and will receive the Awardwinner seal by NaskorSports for a year. Visit the NaskorSports Stand to see which products earned this prestigious recognition! That brings us to the end of our tour. You should now have a good overview of how the Virtual Trade Show is structured. Feel free to explore the venue at your own pace, visit different booths, and learn more about the offerings. If you have any more questions, feel free click the icon in the bottom-right corner.  Enjoy your experience",
  },
] as const;
