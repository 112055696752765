import React, { useState } from 'react';
import { SelectedFlavorProps } from '../../../../../Atoms/ProductAtoms';
import { IProductListProduct } from '../../../ProductList';
import Overlay from './Overlay/Overlay';
import ZoomImage from './ZoomImage/ZoomImage';

type ProductImageProps = {
  product: IProductListProduct;
  selectedFlavor?: SelectedFlavorProps;
};

const ProductImage = ({ product, selectedFlavor }: ProductImageProps) => {
  const [showOverlay, setShowOverlay] = useState(true);
  const handleMouseEnter = () => {
    setShowOverlay(false);
  };

  const handleMouseLeave = () => {
    setShowOverlay(true);
  };
  return (
    <div className="position-relative">
      <div onMouseLeave={handleMouseLeave}>
        <ZoomImage
          src={
            selectedFlavor?.flavorImages?.front_image_url || product.image_uri
          }
        />
      </div>
      {(product.is_new || product.image_overlay_uri) && showOverlay && (
        <div onMouseEnter={handleMouseEnter}>
          <Overlay {...product} />
        </div>
      )}
    </div>
  );
};

export default ProductImage;
