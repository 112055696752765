import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getRequest } from '../../../../../Helper/ApiClient';
import MegaMenuContainer from '../../MegaMenuContainer/MegaMenuContainer';
// @ts-ignore
import styles from './MegaMenuSale.module.scss?module';
import Link from '../../../../../Abstracts/Link/Link';

interface Props {
  show?: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
}

const MegaMenuSale = ({ show, setShowMenu }: Props) => {
  const botmRequest = getRequest('/api/onlineshop/botm');
  const potwRequest = getRequest('/api/onlineshop/potw');
  const { data } = getRequest(
    '/api/onlineshop/promotion/active',
    '/api/onlineshop/promotion/active'
  );

  const handleCloseMegaMenu = () => setShowMenu(false);
 
  return (
    <MegaMenuContainer show={show}>
      <Row>
        {botmRequest.data?.brand && (
          <Col>
            <Link
              onClick={handleCloseMegaMenu}
              to="/product-list/botm"
              className={'d-block fs-4 fw-bold'}
            >
              Brand of the Month
            </Link>
            <Link
              onClick={handleCloseMegaMenu}
              to="/product-list/botm"
              className={'d-block mt-2'}
            >
              {botmRequest.data.brand.name}
            </Link>
          </Col>
        )}
        {potwRequest.data?.products && (
          <Col>
            <Link
              onClick={handleCloseMegaMenu}
              to="/product-list/potw"
              className={'d-block fs-4 fw-bold'}
            >
              Product of the Week
            </Link>

            {potwRequest.data?.products.map((product) => (
              <Link
                onClick={handleCloseMegaMenu}
                to={`/product-list/potw/${product.erp_id}`}
                className={'d-block mt-2'}
                key={product.erp_id}
              >
                {product.brand?.name} {product.name}
              </Link>
            ))}
          </Col>
        )}

        {data?.data.promo && (
          <Col>
            <Link
              onClick={handleCloseMegaMenu}
              to="/product-list/promo"
              className={'d-block fs-4 fw-bold'}
            >
              Monthly promotions
            </Link>
            {data.data.promo.map(({ id, name }) => (
              <Link
                onClick={handleCloseMegaMenu}
                to={`/product-list/promo/${id}`}
                className={'d-block mt-2'}
                key={id}
              >
                {name}
              </Link>
            ))}
            <Link
              onClick={handleCloseMegaMenu}
              to="/product-list/promo"
              className={'d-block mt-2'}
            >
              {'» View all'}
            </Link>
          </Col>
        )}

        {data?.data.sale && (
          <Col>
            <Link
              onClick={handleCloseMegaMenu}
              to="/product-list/sale"
              className={'d-block fs-4 fw-bold'}
            >
              Clearance Deals
            </Link>
            {Object.keys(data.data.sale).map((sale, key) => (
              <Link
                onClick={handleCloseMegaMenu}
                to={`/product-list/sale/${sale}`}
                className={'d-block mt-2'}
                key={key}
              >
                {`${sale}% Discount`}
              </Link>
            ))}
            <Link
              onClick={handleCloseMegaMenu}
              to="/product-list/sale"
              className={'d-block mt-2'}
            >
              {'» View all'}
            </Link>
          </Col>
        )}
      </Row>
    </MegaMenuContainer>
  );
};

export default MegaMenuSale;
