import React from 'react';
//@ts-ignore
import style from './DiscountLabel.module.scss?module';

const DiscountLabel = ({ discountPromotion }) => {
  return (
    <div className={style.DiscountPromotion}>{discountPromotion}% off</div>
  );
};

export default DiscountLabel;
