import React from 'react';
import { INewComerWeekProduct } from '../../../../../ProductsSlideshow/ProductsSlideshow';
import { Image } from 'react-bootstrap';
export default function ProductInformationContent({
  ncwProduct,
  isDesktop,
}: {
  ncwProduct: INewComerWeekProduct;
  isDesktop?: boolean;
}) {
  return (
    <>
      {isDesktop && (
        <div>
          <h2 className="m-0">{ncwProduct.product.brand.name} </h2>{' '}
          <h5 className="m-0 pb-3">{ncwProduct.product.name}</h5>{' '}
        </div>
      )}
      <div>
        <Image
          style={{ borderRadius: '10px', maxWidth: '600px' }}
          className={'w-100'}
          src={ncwProduct.product.image_nf_url}
        />
      </div>
    </>
  );
}
