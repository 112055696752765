import React, { useRef, useState } from 'react';
import { INewComerWeekBrands } from '../../../../../../Interface/Ncw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

//@ts-ignore
import styles from './BrandPagesDesktop.module.scss?module';
import BrandProductList from '../components/BrandProductList/BrandProductList';
import SliderPaginator from '../../../Abstracts/SliderPaginator/SliderPaginator';
import DiscountLabel from '../../../Abstracts/DiscountLabel/DiscountLabel';

type BrandPagesDesktopProps = {
  ncwBrands: INewComerWeekBrands[];
  activeIndex: number;
  slideToPrevBrand: () => void;
  slideToNextBrand: () => void;
};

export type NcwBrandAnimationStepsTypes = {
  hideNoneActiveBrand: boolean;
  moveActiveBrand: boolean;
  showBrandProductList: boolean;
};

const BrandPagesDesktop = ({
  ncwBrands,
  activeIndex,
  slideToPrevBrand,
  slideToNextBrand,
}: BrandPagesDesktopProps) => {
  const cursorRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [cursorType, setCursorType] = useState<
    'none' | 'next' | 'prev' | 'more'
  >('none');
  const [brandDetailVisible, setBrandDetailVisible] = useState(false);
  const [animationSteps, setAnimationSteps] =
    useState<NcwBrandAnimationStepsTypes>({
      hideNoneActiveBrand: false,
      moveActiveBrand: false,
      showBrandProductList: false,
    });

  function handleSlideClick(index: number) {
    const { hideNoneActiveBrand, moveActiveBrand, showBrandProductList } =
      animationSteps;
    if (hideNoneActiveBrand || moveActiveBrand || showBrandProductList) return;
    if (activeIndex == index) {
      // hide Custom Cursor
      setBrandDetailVisible(true);
      setIsHovering(false);
      setCursorType('none');
      // Start playing anmation
      setAnimationSteps((prevVal) => ({
        ...prevVal,
        hideNoneActiveBrand: true,
      }));
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          moveActiveBrand: true,
        }));
      }, 300);
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          showBrandProductList: true,
        }));
      }, 400);
    } else if (index > activeIndex) {
      slideToNextBrand();
    } else if (index < activeIndex) {
      slideToPrevBrand();
    }
  }

  function getActiveCursor() {
    switch (cursorType) {
      case 'none':
        return null;

      case 'next':
        return <FontAwesomeIcon size="xl" icon={faArrowRight} />;

      case 'prev':
        return <FontAwesomeIcon size="xl" icon={faArrowLeft} />;

      case 'more':
        return 'MORE';

      default:
        return null;
    }
  }

  function updateCursor(
    activeProduct: boolean,
    nextActiveProduct: boolean,
    prevActiveProduct: boolean
  ) {
    if (activeProduct) {
      setCursorType('more');
    } else if (nextActiveProduct) {
      setCursorType('next');
    } else if (prevActiveProduct) {
      setCursorType('prev');
    }
  }

  return (
    <>
      {/* todo: make abstrackt compoenent for cursor typs */}
      <div
        ref={cursorRef}
        className={`${styles.CustomCursor} ${
          isHovering ? styles.IsHovering : styles.NotHovering
        }`}
      >
        {!brandDetailVisible && getActiveCursor()}
      </div>

      <div
        onMouseLeave={() => {
          setIsHovering(false);
          setCursorType('none');
        }}
        onMouseMove={(e) => {
          // no mouse Tracking if product Detail is visible
          if (brandDetailVisible) return;
          if (cursorRef && cursorRef.current) {
            cursorRef.current.style.left = `${
              e.clientX - cursorRef.current.offsetWidth / 2
            }px`;
            cursorRef.current.style.top = `${
              e.clientY - cursorRef.current.offsetHeight / 2
            }px`;

            if (!brandDetailVisible && cursorType !== 'none') {
              setIsHovering(true);
            }
          }
        }}
        className="d-flex"
        style={{
          cursor: brandDetailVisible ? 'default' : 'none',
          height: '100%',
          maxWidth: '100vw',
          contain: 'paint',
          flexGrow: '1',
          // cursor: productDetailVisible ? 'default' : 'none',
          // backgroundColor:
          //   productsRequest?.data?.[activeIndex < 0 ? 0 : activeIndex]
          //     ?.colorBackground,
        }}
      >
        <div
          className="d-flex"
          style={{
            minWidth: '100%',
            transform:
              activeIndex !== 0
                ? `translate3d(-${(activeIndex - 1) * (100 / 3)}%, 0 ,0)`
                : `translate3d(${100 / 3}%, 0,0)`,
            transition: 'all 1s',
          }}
        >
          {ncwBrands.map((brand, index) => {
            const activeBrand = index === activeIndex;
            const nextActiveBrand = index > activeIndex;
            const prevActiveBrand = index < activeIndex;
            return (
              <div
                style={{
                  color: brand.colorText || 'white',
                  minWidth: 'calc(100% / 3)',
                  maxHeight: '100%',
                  opacity: activeBrand ? 1 : 0.5,
                  transition: 'all 700ms',
                  position: 'relative',
                  perspective: '100px',
                  transform:
                    activeBrand && animationSteps.moveActiveBrand
                      ? `translate3d(-100%, 0, 0)`
                      : nextActiveBrand && animationSteps.hideNoneActiveBrand
                      ? `translate3d(100% , 0, 0)`
                      : prevActiveBrand && animationSteps.hideNoneActiveBrand
                      ? `translate3d(-100%, 0, 0)`
                      : 'translate3d(0, 0, 0)',
                }}
                key={brand.erp_id}
                onClick={() => handleSlideClick(index)}
                onMouseMove={() => {
                  if (!brandDetailVisible) {
                    updateCursor(activeBrand, nextActiveBrand, prevActiveBrand);
                  }
                }}
              >
                <DiscountLabel discountPromotion={brand.discountPromotion} />
                <BrandProductList
                  setAnimationSteps={setAnimationSteps}
                  brand={brand}
                  activeBrand={activeBrand}
                  animationSteps={animationSteps}
                  setBrandDetailVisible={setBrandDetailVisible}
                />
              </div>
            );
          })}
        </div>
      </div>
      {!brandDetailVisible && (
        <SliderPaginator
          productsDataLength={ncwBrands?.length || 0}
          currentSection={activeIndex}
          onNavigateNext={() => handleSlideClick(activeIndex + 1)}
          onNavigatePrev={() => handleSlideClick(activeIndex - 1)}
          direction={'horizontal'}
        />
      )}
    </>
  );
};

export default BrandPagesDesktop;
