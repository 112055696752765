import React, {
  useState,
  useEffect,
  RefObject,
  ReactNode,
  CSSProperties,
} from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { useBrowserCheck } from '../../../../Hooks/useBrowserCheck';
import { useSetAtom } from 'jotai';
import { NcwIsElementNotSwipingAtom } from '../../Atoms/NcwAtoms';

interface ScrollToTopWrapperProps {
  children: ReactNode;
  containerRef?: RefObject<HTMLElement>;
}

const ScrollToTopButton: React.FC<{
  onClick: () => void;
  isVisible: boolean;
}> = ({ onClick, isVisible }) => {
  const { checkBrowser } = useBrowserCheck();
  const browserSpecificStyles: CSSProperties = checkBrowser(['isSafariIOS'])
    ? {
        bottom: '7.5rem',
      }
    : {
        bottom: '6rem',
      };

  return ReactDOM.createPortal(
    <button
      onClick={onClick}
      className="fw-bold btn btn-primary rounded-circle"
      style={{
        ...browserSpecificStyles,
        position: 'fixed',
        right: isVisible ? '2.5rem' : '-4rem',
        width: '3rem',
        height: '3rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.3s',
        zIndex: 1050,
        padding: 0,
      }}
      aria-label="Scroll to top"
    >
      <span>
        <FontAwesomeIcon size="xl" icon={faChevronUp} />
      </span>
      <span>TOP</span>
    </button>,
    document.body
  );
};

export const ScrollToTopWrapper: React.FC<ScrollToTopWrapperProps> = ({
  children,
  containerRef,
}) => {
  const setElementNotSwiping = useSetAtom(NcwIsElementNotSwipingAtom);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const container = containerRef?.current || window;

    const toggleVisibility = () => {
      const scrollElement = containerRef?.current ?? document.documentElement;
      const scrollTop = scrollElement.scrollTop;
      const viewportHeight =
        containerRef?.current?.clientHeight ?? window.innerHeight;

      if (scrollTop > viewportHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    container.addEventListener('scroll', toggleVisibility);

    return () => container.removeEventListener('scroll', toggleVisibility);
  }, [containerRef]);

  return (
    <>
      {children}
      <ScrollToTopButton
        onClick={() => {
          if (!containerRef) return;
          scrollToTop(setElementNotSwiping, containerRef);
        }}
        isVisible={isVisible}
      />
    </>
  );
};

export const scrollToTop = (
  setFunc: React.Dispatch<React.SetStateAction<boolean>>,
  elementRef?: React.RefObject<HTMLElement>,
  behavior?: ScrollBehavior,
  startTime?: number,
  endTime?: number
) => {
  setFunc(false);

  setTimeout(() => {
    const scrollElement =
      elementRef && elementRef.current ? elementRef.current : window;
    scrollElement.scrollTo({
      top: 0,
      behavior: behavior || 'smooth',
    });
  }, startTime ?? 200);

  setTimeout(() => {
    setFunc(true);
  }, endTime ?? 1500);
};
