import React, { SetStateAction, useRef } from 'react';

import { Image } from 'react-bootstrap';
import NcwCircleXButton from '../../../../../../Abstracts/NcwCircleXButton/NcwCircleXButton';
import { INewComerWeekBrands } from '../../../../../../../Interface/Ncw';
import { NcwBrandAnimationStepsTypes } from '../../BrandPagesDesktop/BrandPagesDesktop';
import NcwBrandCategories from './NcwBrandCategories/NcwBrandCategories';
import { ScrollToTopWrapper } from '../../../../Abstracts/ScrollToTopWrapper/ScrollToTopWrapper';
import BrandPresentation from '../BrandPresentation/BrandPresentation';

type BrandProductListType = {
  brand: INewComerWeekBrands;
  activeBrand: boolean;
  animationSteps: NcwBrandAnimationStepsTypes;
  setAnimationSteps: React.Dispatch<
    SetStateAction<NcwBrandAnimationStepsTypes>
  >;
  setBrandDetailVisible: React.Dispatch<SetStateAction<boolean>>;
};

const BrandProductList = ({
  brand,
  activeBrand,
  animationSteps,
  setAnimationSteps,
  setBrandDetailVisible,
}: BrandProductListType) => {
  const containerRef = useRef<HTMLDivElement>(null);
  function handleCloseActiveProduct() {
    if (setAnimationSteps && setBrandDetailVisible && activeBrand) {
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          showBrandProductList: false,
        }));
      }, 200);
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          moveActiveBrand: false,
        }));
      }, 600);

      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          hideNoneActiveBrand: false,
        }));
        setBrandDetailVisible(false);
      }, 900);
    }
  }

  return (
    <>
      <Image
        width={'100%'}
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          transition: 'all 1000ms',
          position: 'absolute',
        }}
        src={brand.brand_image_url}
      />
      {activeBrand && (
        <video
          src={brand.brand_video_url}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            transition: 'all 1000ms',
            position: 'absolute',
          }}
          controlsList="nofullscreen"
          disablePictureInPicture
          muted
          autoPlay
          controls={false}
          loop
          playsInline
        />
      )}
      {activeBrand && animationSteps.hideNoneActiveBrand && (
        <>
          <div
            ref={containerRef}
            style={{
              overflowY: 'auto',
              background: brand.colorBackground || 'black',
              height: '100%',
              width: '200%',
              position: 'absolute',
              transform:
                activeBrand &&
                animationSteps &&
                animationSteps.showBrandProductList
                  ? 'translate3d(50%, 0, 0) rotatex(0deg)'
                  : 'translate3d(50%, 100%, 20px) rotatey(5deg)',
              transition: 'all 1000ms ease-in-out',
              opacity:
                activeBrand &&
                animationSteps &&
                animationSteps.showBrandProductList
                  ? 1
                  : 0,
            }}
          >
            <ScrollToTopWrapper containerRef={containerRef}>
              <NcwCircleXButton
                color={brand.colorText || 'white'}
                onClick={handleCloseActiveProduct}
              />
              <BrandPresentation ncwBrand={brand} />
              <NcwBrandCategories ncwBrand={brand} />
            </ScrollToTopWrapper>
          </div>
        </>
      )}
    </>
  );
};

export default BrandProductList;
