import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { NaskorBasket } from '../../js/components/naskor';
import { getRequest } from '../Helper/ApiClient';

interface User {
  id: number;
  name: string;
  email: string;
  erp_unpaid_sums: number;
  favourites_count: number;
  ROLE_ALLOWED_TO_SWITCH?: boolean;
  ROLE_PREVIOUS_ADMIN?: boolean;
  is_exhibitor?: boolean;
  has_api_access?: boolean;
  bonus?: any;
  vts_orders_visible?: boolean;
  prepay: boolean;
  invoice_country_id?: number;
  invoice_country_name?: string;
}

interface BasketHeaderInfo {
  basket_total_quantity: number;
  basket_total_price: number;
}

interface IUserContext {
  user: User | null;
  basketInfo: BasketHeaderInfo | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loading: boolean;
  fetchUser: () => void;
  updateFavouritesCount: (value: number) => void;
}

const UserContext = createContext<IUserContext>({
  user: null,
  basketInfo: null,
  // @ts-ignore
  setUser: null,
  // @ts-ignore
  fetchUser: null,
  loading: false,
  // @ts-ignore
  updateFavouritesCount: null,
});

export function UserProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null);
  const [basketInfo, setBasketInfo] = useState<BasketHeaderInfo | null>(null);

  const { data, isSuccess, isLoading, refetch } = getRequest<{
    user: User | null;
    basket_info: BasketHeaderInfo | null;
  }>('/api/current_user');

  useEffect(() => {
    if (isSuccess && data) {
      setUser(data.user);
      setBasketInfo(data.basket_info);
    } else {
      setUser(null);
      setBasketInfo(null);
    }
  }, [data]);

  const fetchUser = () => {
    refetch();
  };

  const updateFavouritesCount = (value: number) => {
    if (user) {
      setUser({ ...user, favourites_count: value });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        basketInfo,
        fetchUser,
        loading: isLoading,
        updateFavouritesCount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within UserProvider');
  }
  return context;
}
