import React from 'react'; // @ts-ignore
import styles from './Basket.module.scss?module';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { getRequest } from '../../../Helper/ApiClient';
import { NcwBasket } from '../../../../Interface/Ncw';
import { formatCurrencyEur, formatDate } from '../../../Helper/formatter';
import QuantityInput from './QuantityInput/QuantityInput';
import LoadingSpinner from '../../../Abstracts/LoadingSpinner/LoadingSpinner';
import { useUser } from '../../../Context/UserContext';
import PlaceOrderButton from './PlaceOrderButton/PlaceOrderButton';
import ClearBasket from './ClearBasket/ClearBasket';
import EmptyBasketContent from './EmptyBasketContent/EmptyBasketContent';
import Summary from './Summary/Summary';
import NcwOrderDetails from './NcwOrderDetails/NcwOrderDetails';
import { useNcw } from '../Context/NcwContext';
import usePatchMutation from '../../../Helper/usePatchMutation';

export default function Basket() {
  const basketRequest = getRequest<NcwBasket>(
    `/api/onlineshop/newcomer-week/basket`
  );

  const patchMutation = usePatchMutation(
    `/api/onlineshop/newcomer-week/basket`
  );

  React.useEffect(() => {
    if (patchMutation.isSuccess) {
      basketRequest.refetch();
    }
  }, [patchMutation.isSuccess]);

  const { refetchNcwBasketHeader } = useNcw();
  const { fetchUser } = useUser();
  const basket = basketRequest.data;

  const refetchBasket = () => {
    basketRequest.refetch();
    fetchUser();
    refetchNcwBasketHeader();
  };

  const hrRow = (
    <Row>
      <Col>
        <hr />
      </Col>
    </Row>
  );

  const [showThankYou, setShowThankYou] = React.useState(false);
  const handlePlaceOrderSuccess = () => {
    if (basket?.is_backorder_confirmation) {
      setShowThankYou(true);
    }
    refetchBasket();
  };

  if (basketRequest.isLoading) {
    return (
      <Container className={'py-6 text-center'}>
        <Row>
          <Col>
            <LoadingSpinner />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      {showThankYou ? (
        <Row className={'py-5 text-center'}>
          <Col>
            <h1>Thank you for your backorder confirmation.</h1>
            <p>
              <a href={'/newcomer-week/home'}>
                Continue shopping
              </a>
            </p>
          </Col>
        </Row>
      ) : (
        <>
          {basketRequest?.data?.erp_sales_order_id ? (
            <>
              <NcwOrderDetails
                order_id={basketRequest?.data?.erp_sales_order_id}
              />
              {hrRow}
            </>
          ) : (
            <>
              {basketRequest?.data?.is_backorder_confirmation && (
                <>
                  <Row>
                    <Col>
                      <h1 className="pt-5">Your Order</h1>
                      <div className={'py-3'}>
                        Thank you for your trust.
                        <br />
                        Part of your order is only available at the ETA
                        indicated below. Take the opportunity and adjust your
                        quantities.
                      </div>
                    </Col>
                  </Row>
                  {hrRow}
                </>
              )}
            </>
          )}
          {basket && basket.positions.length == 0 ? (
            <EmptyBasketContent />
          ) : (
            <>
              <Row className={'pt-5'}>
                <Col md={10}>
                  {basketRequest?.data?.is_backorder_confirmation && (
                    <div className={'pb-3'} style={{ color: 'red' }}>
                      Please confirm your quantities or empty the shopping
                      cart to continue participating in the Newcomer Week.
                    </div>
                  )}
                  <h1>Your Basket</h1>
                </Col>
                <Col md={2} className="text-end">
                  <ClearBasket onClearBasketSuccess={refetchBasket} />
                </Col>
              </Row>

              {hrRow}

              {basket && (
                <>
                  <Row>
                    <Col>
                      <Container>
                        <Row className={'d-none d-lg-flex'}>
                          <Col lg={9}>
                            <h3>Product</h3>
                          </Col>
                          <Col>
                            <h3>Quantity</h3>
                          </Col>
                          <Col className={'text-end'}>
                            <h3>Total</h3>
                          </Col>
                        </Row>

                        {basket.positions.map((position) => (
                          <Row
                            className={'py-2 position-relative'}
                            key={position.id}
                          >
                            <Col lg={3} className={'d-flex'}>
                              <Row>
                                <Col xs={4}>
                                  <Image
                                    src={
                                      position.product_attribute
                                        .image_white_public_url
                                    }
                                    className={'w-100'}
                                  />
                                </Col>
                                <Col xs={8}>
                                  <strong>
                                    {
                                      position.product_attribute?.product_size
                                        ?.product?.brand?.name
                                    }
                                  </strong>
                                  <div>
                                    {
                                      position.product_attribute?.product_size
                                        ?.name
                                    }
                                  </div>
                                  <div className={'d-lg-none'}>
                                    {position.product_attribute.name}
                                  </div>

                                  <div
                                    className={'d-lg-none pt-1 pb-3'}
                                    style={{
                                      fontSize: '.8rem',
                                      lineHeight: '1rem',
                                    }}
                                  >
                                    <div>
                                      Labels{' '}
                                      {position.product_attribute?.product_size
                                        ?.has_label
                                        ? ''
                                        : 'not'}{' '}
                                      available
                                    </div>
                                    <div>
                                      Price:
                                      {formatCurrencyEur(
                                        position.single_price_exc_vat
                                      )}
                                    </div>
                                    {position.single_deposit_price_exc_vat >
                                      0 && (
                                      <div className={'pb-2'}>
                                        Deposit:{' '}
                                        {formatCurrencyEur(
                                          position.single_deposit_price_exc_vat
                                        )}
                                      </div>
                                    )}
                                    {position.single_lemonade_fee_price_exc_vat >
                                      0 && (
                                      <div className={'pb-2'}>
                                        Verbruiksbelasting fee:{' '}
                                        {formatCurrencyEur(
                                          position.single_lemonade_fee_price_exc_vat
                                        )}
                                      </div>
                                    )}
                                    {basket.is_backorder_confirmation &&
                                      position.eta && (
                                        <div>
                                          ETA: {formatDate(position.eta)}
                                        </div>
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={3}>
                              <div>
                                <strong>Flavour:&nbsp;</strong>
                                {position.product_attribute.name}
                              </div>
                              <div>
                                <strong>Price:&nbsp;</strong>
                                {formatCurrencyEur(
                                  position.single_price_exc_vat
                                )}
                              </div>
                              <div>
                                <strong>Product label:&nbsp;</strong>
                                {position.product_attribute.product_size
                                  ?.has_label
                                  ? 'Available'
                                  : 'Not Available'}
                              </div>
                              {position.single_deposit_price_exc_vat > 0 && (
                                <div>
                                  <strong>Deposit:&nbsp;</strong>
                                  {formatCurrencyEur(
                                    position.single_deposit_price_exc_vat
                                  )}
                                </div>
                              )}
                              {position.single_lemonade_fee_price_exc_vat >
                                0 && (
                                <div>
                                  <strong>Verbruiksbelasting fee:&nbsp;</strong>
                                  {formatCurrencyEur(
                                    position.single_lemonade_fee_price_exc_vat
                                  )}
                                </div>
                              )}
                              {basket.is_backorder_confirmation &&
                                position.eta && (
                                  <div>
                                    <strong>ETA:&nbsp;</strong>
                                    {formatDate(position.eta)}
                                  </div>
                                )}
                            </Col>
                            <Col lg={3}></Col>
                            <Col>
                              <QuantityInput
                                key={position.id + '_' + position.quantity}
                                ncwBasketPosition={position}
                                onQtyUpdateSuccess={refetchBasket}
                              />
                            </Col>
                            <Col className={'text-end fw-bold'}>
                              {formatCurrencyEur(
                                position.single_price_exc_vat *
                                  position.quantity
                              )}
                            </Col>
                          </Row>
                        ))}

                        {/* {!basket.is_sample_ordered &&
                          basket.has_ncw_sample_stock &&
                          basket.is_sample_allowed && (
                            <>
                              <Row>
                                <Col>
                                  <hr />
                                </Col>
                              </Row>
                              <Row className={'py-2 position-relative'}>
                                <Col lg={3} className={'d-flex'}>
                                  <Row>
                                    <Col xs={4}>
                                      <Image
                                        src={
                                          'https://files.naskorsports.com/shop/newcomer-week/basket/ncw-sample-box.png'
                                        }
                                        className={'w-100'}
                                      />
                                    </Col>
                                    <Col xs={8}>
                                      <div>
                                        <strong>Sample Box</strong>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={6}></Col>
                                <Col>
                                  <div className="d-flex align-items-center">
                                    <Form.Check
                                      inline
                                      type="checkbox"
                                      name={'is_sample_requested'}
                                      defaultChecked={
                                        basket.is_sample_requested
                                      }
                                      onChange={patchMutation.onUpdateHandler}
                                    />
                                  </div>
                                </Col>
                                <Col className={'text-end fw-bold'}>
                                  {formatCurrencyEur(0)}
                                </Col>
                              </Row>
                            </>
                          )} */}
                      </Container>
                    </Col>
                  </Row>

                  {hrRow}

                  <Summary basket={basket} onUpdateSuccess={refetchBasket} />

                  {hrRow}

                  <Row>
                    <Col className={'text-end pt-3 pb-5'}>
                      {basket.is_backorder_confirmation ? (
                        <span className="fw-bold d-block d-sm-inline text-start">
                          Backorder: Quantity will be shipped and invoiced at
                          the indicated ETA, feel free to adjust according to
                          your needs.
                        </span>
                      ) : (
                        <span className="fw-bold d-block d-sm-inline text-start">
                          Place your order and we will confirm your quantities
                          immediately
                        </span>
                      )}
                      &nbsp;
                      <PlaceOrderButton
                        basket={basket}
                        onPlaceOrderSuccess={handlePlaceOrderSuccess}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}
