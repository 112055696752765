import { useSetAtom } from 'jotai';
import { startGuidedTourAtom } from '../Atoms/vtsAtoms';

export const useHandleLeaveGuidedTour = () => {
  const startGuidedTour = useSetAtom(startGuidedTourAtom);

  const stopGuidedTour = () => {
    startGuidedTour(false);
  };

  return {
    stopGuidedTour,
  };
};
