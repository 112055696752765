import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { NcwIsElementNotSwipingAtom } from '../Router/NewcomerWeek/Atoms/NcwAtoms';

const useNewcomerWeekHTMLConfig = (className: string) => {
  const isElementNotSwiping = useAtomValue(NcwIsElementNotSwipingAtom);

  useEffect(() => {
    const rootElement = document.documentElement;
    rootElement.className = className;
    rootElement.style.scrollSnapType = isElementNotSwiping
      ? 'y mandatory'
      : 'none';
    rootElement.style.scrollPaddingTop = '56px';
    //@ts-ignore
    rootElement.style.scrollbarWidth = 'none';
    rootElement.style.overscrollBehavior = 'none';

    return () => {
      rootElement.style.scrollSnapType = '';
    };
  }, [isElementNotSwiping]);
};

export default useNewcomerWeekHTMLConfig;
