import React, { useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import useViewports from '../../../../../../Hooks/useViewports';
import { IProductListProduct } from '../../../../ProductList';
import { Image } from 'react-bootstrap';
//@ts-ignore
import Styles from './ZoomImage.module.scss?module';

const DefaultImageSize = 1000;

type ZoomImagePropsT = {
  src: IProductListProduct['image_uri'];
  alt?: string;
  containerW?: string;
  containerH?: string;
};

const ZoomImage = ({
  src,
  alt = 'product image',
  containerW = '330%',
  containerH = '250%',
}: ZoomImagePropsT) => {
  const [imageDimensions, setImageDimensions] = useState({
    width: DefaultImageSize,
    height: DefaultImageSize,
  });
  const { xs, sm, md } = useViewports();

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const { naturalWidth, naturalHeight } = event.target as HTMLImageElement;
    if (
      naturalHeight !== DefaultImageSize ||
      naturalWidth !== DefaultImageSize
    ) {
      setImageDimensions({ width: naturalWidth, height: naturalHeight });
    }
  };

  return (
    <div className={Styles.ImageContainer}>
      {xs || sm || md ? (
        <Image fluid src={src} className="h-auto" />
      ) : (
        <ReactImageMagnify
          {...{
            smallImage: {
              alt,
              isFluidWidth: true,
              src,
              onload: handleImageLoad,
            },
            largeImage: {
              src,
              width: imageDimensions.width,
              height: imageDimensions.height,
            },
            enlargedImageContainerDimensions: {
              width: containerW,
              height: containerH,
            },
            enlargedImageContainerClassName: Styles.ZoomContainer,
            hoverDelayInMs: 0,
            pressDuration: 0,
            fadeDurationInMs: 100,
          }}
        />
      )}
    </div>
  );
};

export default ZoomImage;
