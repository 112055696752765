import { useState, useEffect } from "react";

export type ViewportType = 'mobile' | 'desktop' | ''

const useViewport = (breakepoint: number) => {
  const [viewport, setViewport] = useState<ViewportType>('mobile');

  function checkUserScreen() {
    setViewport(window.innerWidth < breakepoint ? 'mobile' : 'desktop');
  }

  useEffect(() => {
    checkUserScreen();
    window.addEventListener('resize', checkUserScreen);
    return () => window.removeEventListener('resize', checkUserScreen);
  }, []);
  
  return viewport;

};

export default useViewport;