import React, { FC, useEffect, forwardRef } from 'react';
import { Container } from 'react-bootstrap';

//@ts-ignore
import styles from './SlideShowSlider.module.scss?module';
import ProductSlidePageImage from './VerticalSections/ProductSlidePageImage/ProductSlidePageImage';
import ProductSlidePageInformation from './VerticalSections/ProductSlidePageInformation/ProductSlidePageInformation';
// import ProductSlidePageOrderList from './VerticalSections/ProductSlidePageOrderList/ProductSlidePageOrderList';
import { INewComerWeekProduct } from '../../ProductsSlideshow/ProductsSlideshow';
import ProductSlidePageProducts from './VerticalSections/ProductSlidePageProducts/ProductSlidePageProducts';
import { useSetAtom } from 'jotai';
import { NcwIsElementNotSwipingAtom } from '../../../Atoms/NcwAtoms';
import { scrollToTop } from '../../../Abstracts/ScrollToTopWrapper/ScrollToTopWrapper';

type SlideShowSliderPropsT = {
  index: number;
  activeIndex: number;
  ncwProduct: INewComerWeekProduct;
};

const SlideShowSlider = forwardRef<HTMLDivElement, SlideShowSliderPropsT>(
  ({ index, activeIndex, ncwProduct }, ref) => {
    const setElementNotSwiping = useSetAtom(NcwIsElementNotSwipingAtom);

    useEffect(() => {
      // scroll nach ganz oben nur einmal beim besuchen der component
      if (index == 0) {
        scrollToTop(setElementNotSwiping);
      }
    }, []);

    return (
      <Container
        fluid
        className={`${styles.Container} ${
          activeIndex == index && styles.ActiveProduct
        }`}
        style={{
          backgroundColor: ncwProduct.colorBackground || 'black',
          zIndex: 0,
          color: ncwProduct.colorText || 'white',
          height: activeIndex == index ? '' : '1px',
        }}
        ref={ref}
      >
        <ProductSlidePageImage ncwProduct={ncwProduct} />
        <ProductSlidePageProducts ncwProduct={ncwProduct}>
          <ProductSlidePageInformation ncwProduct={ncwProduct} />
        </ProductSlidePageProducts>
      </Container>
    );
  }
);

export default SlideShowSlider;
