import * as React from 'react';
import { Col, FormControl, Image, Row } from 'react-bootstrap';
import { ProductSizeData } from '../../../Products/ProductsSlideshow/ProductsSlideshow';
import { formatCurrency } from '../../../../../Helper/formatter';
import Popup from '../../../../../Abstracts/Popup/Popup';

//@ts-ignore
import styles from './NcwProductSize.module.scss?module';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';

export default function NcwProductSize({
  productSizeData,
  color,
  backgroundColor,
  componentRef,
  onChange,
  isBrand = false,
}: {
  productSizeData: ProductSizeData;
  color?: string;
  backgroundColor?: string;
  componentRef?: (instance: HTMLInputElement | null) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isBrand?: boolean;
}) {
  const [clickedImgSrc, setClickedImgSrc] = React.useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      onChange && onChange(e);
    } else {
      e.target.value = value.replace(/\D/g, '');
      const event = new Event('input', { bubbles: true });
      e.target.dispatchEvent(event);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/\d/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Row className={styles.SizeRow}>
        <Col lg={6}>
          <Row className={styles.SizeNameAboveImage}>
            <Col>
              <h4>{productSizeData.name}</h4>
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              className={`${
                !isBrand ? 'd-none' : ''
              } cursor-pointer position-relative`}
            >
              <Swiper
                pagination={{ clickable: true }}
                spaceBetween={5}
                modules={[Pagination]}
                className={styles.SwiperWrapper}
              >
                <SwiperSlide
                  onClick={() => {
                    setClickedImgSrc(productSizeData.image_transparent_url);
                  }}
                  style={{ backgroundColor: backgroundColor }}
                >
                  <Image
                    alt={'Image of ' + productSizeData.name}
                    className="w-100"
                    src={productSizeData.image_transparent_url}
                  />
                </SwiperSlide>
                {productSizeData.image_nf_url && (
                  <SwiperSlide
                    onClick={() => {
                      setClickedImgSrc(productSizeData.image_nf_url!);
                    }}
                    style={{ backgroundColor: backgroundColor }}
                  >
                    <Image
                      alt={'nutration facts Image of ' + productSizeData.name}
                      className={`${styles.ImageNF} w-100`}
                      src={productSizeData.image_nf_url!}
                    />
                  </SwiperSlide>
                )}
              </Swiper>
              <ProductImageModal
                showClickedImg={clickedImgSrc !== ''}
                setClickedImgSrc={setClickedImgSrc}
                backgroundColor={backgroundColor}
                color={color}
                imgSrc={clickedImgSrc}
              />
            </Col>
            <Col xs={isBrand ? 6 : 12}>
              <div className={styles.SizeNameAbovePromo}>
                <h4>{productSizeData.name}</h4>
              </div>
              <div className={styles.SizePromo}>
                {productSizeData.promotion_discount}% Off
              </div>
              <div className={styles.SizePriceReference}>
                {formatCurrency(productSizeData.regular_price)}€
                {/* <span>&nbsp;EUR</span> */}
              </div>
              <div className={styles.SizePriceDiscounted}>
                {formatCurrency(productSizeData.price)}€
                {/* <span>&nbsp;EUR</span> */}
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <table className={styles.SizeFlavoursTable}>
            <tbody>
              {productSizeData.productAttributes.map((productAttributeData) => (
                <React.Fragment key={productAttributeData.erp_id}>
                  <tr className={styles.SizeFlavoursTableRow}>
                    <td>{productAttributeData.name}</td>
                    <td className={'text-end'} style={{ width: '30%' }}>
                      <FormControl
                        id={`desktop_${productAttributeData.erp_id}`}
                        ref={componentRef}
                        className={styles.SizeFlavoursTableQty}
                        min={1}
                        type="text"
                        inputMode="numeric"
                        pattern="\d*"
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        onPaste={(e) => {
                          const pastedData = e.clipboardData.getData('text');
                          if (!/^\d*$/.test(pastedData)) {
                            e.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        data-price={productSizeData.price}
                        data-product_attribute_id={productAttributeData.id}
                        style={{
                          backgroundColor:
                            // backgroundColor || 'rgba(0, 0, 0, 0.25)',
                            'rgba(0, 0, 0, 0.25)',
                          color: color || 'white',
                        }}
                        placeholder={'0'}
                      />
                    </td>
                  </tr>
                  <tr className={styles.SizeFlavoursDividerRow}>
                    <td colSpan={2} className={'pt-1'}></td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
}

const ProductImageModal = ({
  showClickedImg,
  setClickedImgSrc,
  imgSrc,
  backgroundColor,
  color,
}) => {
  return (
    <Popup
      contentClassName={styles.ModalContent}
      dialogClassName={styles.ModalDialog}
      centered
      size="lg"
      show={showClickedImg}
      onHide={() => {
        setClickedImgSrc('');
      }}
    >
      {showClickedImg && (
        <>
          <Popup.Header
            closeButton
            style={{ backgroundColor }}
            className={styles.ModalHeader}
            closeVariant={color === '#ffffff' ? 'white' : undefined}
          />
          <Popup.Body className={styles.ModalBody} style={{ backgroundColor }}>
            <img
              className="w-100"
              alt="product-image"
              src={imgSrc}
            />
          </Popup.Body>
        </>
      )}
    </Popup>
  );
};
