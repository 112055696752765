import React, { Fragment } from 'react';
import { INewComerWeekProduct } from '../../Products/ProductsSlideshow/ProductsSlideshow';
import SlideShowSlider from '../../Products/ProductPages/SlideShowSlider/SlideShowSlider';
import Paginator from '../../Paginator/Paginator';
import { useSliderGesture } from '../../../../Hooks/useSliderGesture';

interface MobileNewSliderDesignPDDProps {
  currentSection: number;
  productsData: INewComerWeekProduct[] | undefined;
  setCurrentSection: (section: number) => void;
}

const MobileNewSliderDesignPDD: React.FC<MobileNewSliderDesignPDDProps> = ({
  currentSection,
  productsData,
  setCurrentSection,
}) => {
  function slideToNextProduct() {
    setCurrentSection(
      Math.min(currentSection + 1, (productsData?.length || 0) - 1)
    );
  }

  function slideToPrevProduct() {
    setCurrentSection(Math.max(currentSection - 1, 0));
  }

  const sliderRef = useSliderGesture({
    onSwipeLeft: slideToPrevProduct,
    onSwipeRight: slideToNextProduct,
  });

  return (
    <>
      <div
        style={{
          overscrollBehavior: 'none',
          maxWidth: '100vw',
          contain: 'paint',
        }}
      >
        <div
          ref={sliderRef}
          style={{
            display: 'flex',
            transition: 'transform 750ms ease',
            overscrollBehavior: 'contain',
          }}
        >
          <div
            style={{
              display: 'flex',
              transform: `translate3d(-${currentSection}00vw, 0, 0)`,
              transition: 'transform 500ms ease-in-out',
            }}
          >
            {productsData?.map((product, index) => (
              <Fragment key={product.erp_id}>
                <SlideShowSlider
                  index={index}
                  activeIndex={currentSection}
                  ncwProduct={product}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <Paginator
        requestData={productsData}
        slideToNextItem={slideToNextProduct}
        slideToPrevItem={slideToPrevProduct}
        currentSlideNum={currentSection}
      />
    </>
  );
};

export default MobileNewSliderDesignPDD;
