import React, { FC, useEffect } from 'react';
import {
  INewComerWeekProduct,
  ProductData,
} from '../../Products/ProductsSlideshow/ProductsSlideshow';
import ProductInformationContent from '../../Products/ProductPages/SlideShowSlider/VerticalSections/ProductSlidePageInformation/ProductInformationContent/ProductInformationContent';
import NcwProductList from '../../Abstracts/NcwProductList/NcwProductList';
import SliderPaginator from '../../Abstracts/SliderPaginator/SliderPaginator';
import DiscountLabel from '../../Abstracts/DiscountLabel/DiscountLabel';

type DeskNewSliderDesignPDDProps = {
  productsData: INewComerWeekProduct[] | undefined;
  productsDataLength: number;
  currentSection: number;
  setCurrentSection: React.Dispatch<React.SetStateAction<number>>;
  scrollStates: { [key: number]: number };
  setScrollStates: React.Dispatch<
    React.SetStateAction<{ [key: number]: number }>
  >;
  handleUpdateProductsDataLength: () => void;
};

let ticking = false;
let ColPerRow = 3;
const SliderHeight = window.innerHeight - 56;
// let lastScrollTop = 0;

const DeskNewSliderDesignPD: React.FC<DeskNewSliderDesignPDDProps> = ({
  productsData,
  productsDataLength,
  currentSection,
  setCurrentSection,
  scrollStates,
  setScrollStates,
  handleUpdateProductsDataLength,
}) => {
  // um performance zu gewinnen wird die productsDataLength dynamisch angepasst
  useEffect(() => {
    handleUpdateProductsDataLength();
  }, [currentSection, productsDataLength, productsData]);

  function handleScroll() {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const scrolled = window.scrollY;
        // const scrollDirection = window.scrollY > lastScrollTop ? 'down' : 'up';
        const currentSectionIndex = Math.floor(scrolled / SliderHeight);

        // lastScrollTop = scrolled;

        if (currentSection != currentSectionIndex + 1) {
          setCurrentSection(currentSectionIndex);
        }

        const sectionStart = currentSectionIndex * SliderHeight;
        const sectionScroll = scrolled - sectionStart;
        const sectionPercentage = Math.min(
          (sectionScroll / SliderHeight) * 100,
          100
        );

        setScrollStates((prevStates) => {
          const newStates: { [key: number]: number } = { ...prevStates };
          for (let i = 0; i < currentSectionIndex; i++) {
            newStates[i] = 100;
          }
          newStates[currentSectionIndex] = sectionPercentage;
          return newStates;
        });

        ticking = false;
      });
      ticking = true;
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleTranslateContainer(id: string, scrollPercentage: number) {
    if (id === '1')
      return `translate3d(-${scrollPercentage * 2}px, -${
        scrollPercentage * 2
      }px, 0px)`;
    if (id === '2') return `translate3d(0px, -${scrollPercentage * 2}px, 0px)`;
    if (id === '3')
      return `translate3d(${scrollPercentage * 2}px, -${
        scrollPercentage * 2
      }px, 0px)`;
    return '';
  }

  function handleTranslateContainerReverse(
    id: string,
    scrollPercentage: number
  ) {
    if (id === '1')
      return `translate3d(-${scrollPercentage}px, ${
        scrollPercentage * 2
      }px, 0px)`;
    if (id === '2') return `translate3d(0px, ${scrollPercentage * 2}px, 0px)`;
    if (id === '3')
      return `translate3d(${scrollPercentage}px, ${
        scrollPercentage * 2
      }px, 0px)`;
    return '';
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        contain: 'paint',
        position: 'relative',
      }}
    >
      {productsData?.slice(0, productsDataLength)?.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${ColPerRow}, 1fr)`,
            scrollSnapAlign: 'start',
          }}
        >
          {/* product image section */}
          <SliderPartComponent>
            <SliderLayer
              style={{
                backgroundImage: `url(${item?.backgroundUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
              id={'1'}
              animationTranslate={
                index === currentSection
                  ? handleTranslateContainer('1', scrollStates[index] || 0)
                  : index === currentSection + 1
                  ? handleTranslateContainerReverse(
                      '1',
                      100 - (scrollStates[currentSection] || 0)
                    )
                  : ''
              }
            >
              <ProductDetail
                productData={item.product}
                discountPromotion={item.discountPromotion}
              />
            </SliderLayer>
          </SliderPartComponent>

          {/* product information section */}
          <SliderPartComponent>
            <SliderLayer
              animationTranslate={
                index === currentSection
                  ? handleTranslateContainer('2', scrollStates[index] || 0)
                  : index === currentSection + 1
                  ? handleTranslateContainerReverse(
                      '2',
                      100 - (scrollStates[currentSection] || 0)
                    )
                  : ''
              }
              id={'2'}
            >
              <div
                className={'CustomScrollbar'}
                style={{
                  display: 'flex',
                  height: 'inherit',
                  backgroundColor: item.colorBackground || 'black',
                  minHeight: '100%',
                  minWidth: '100%',
                  maxHeight: '100%',
                  overflowY: 'auto',
                }}
              >
                <div
                  style={{
                    // fontFamily: 'newcomer-week',
                    color: item.colorText || 'white',
                    fontSize: '1rem',
                    width: '100%',
                    padding: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                  }}
                >
                  <ProductInformationContent ncwProduct={item} isDesktop />
                </div>
              </div>
            </SliderLayer>
          </SliderPartComponent>

          {/* product flavor order section */}
          <SliderPartComponent>
            <SliderLayer
              animationTranslate={
                index === currentSection
                  ? handleTranslateContainer('3', scrollStates[index] || 0)
                  : index === currentSection + 1
                  ? handleTranslateContainerReverse(
                      '3',
                      100 - (scrollStates[currentSection] || 0)
                    )
                  : ''
              }
              id={'3'}
            >
              <div
                // className={style.PageProductContainer}
                style={{
                  backgroundColor: item.colorBackground || 'black',
                  height: '100%',
                  width: '100%',
                }}
              >
                <NcwProductList
                  ncwProduct={item}
                  // handleCloseActiveProduct={handleCloseActiveProduct}
                  // onAddToBasketSuccess={() => ncwContext.refetchNcwBasketHeader()}
                />
              </div>
            </SliderLayer>
          </SliderPartComponent>
        </div>
      ))}

      <SliderPaginator
        productsDataLength={productsData?.length || 0}
        currentSection={currentSection}
        sliderHeight={SliderHeight}
      />
    </div>
  );
};

export default DeskNewSliderDesignPD;

type SliderPartComponentProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: string;
};

const SliderPartComponent = ({
  children,
  color,
  className,
  style,
  ...rest
}: SliderPartComponentProps) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center px-1 ${className}`}
      style={{
        minHeight: `${SliderHeight}px`,
        background: color,
        ...(style || {}),
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

type SliderLayerProps = {
  children?: React.ReactNode;
  id: string;
  animationTranslate?: string;
  item?: INewComerWeekProduct | undefined;
  style?: React.CSSProperties;
};

const SliderLayer = React.forwardRef<HTMLDivElement, SliderLayerProps>(
  ({ children, id, animationTranslate, item, style }, ref) => {
    return (
      <div
        id={id}
        ref={ref}
        style={{
          width: '100%',
          minHeight: 'calc(100vh - 75px)',
          height: 'calc(100vh - 75px)',
          maxHeight: 'calc(100vh - 75px)',
          borderRadius: '10px',
          transform: animationTranslate,
          transition: 'all 10ms ease-in-out',
          willChange: 'transform',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          ...(style || {}),
        }}
      >
        {children}
      </div>
    );
  }
);

type ProductDetailProps = {
  productData: ProductData;
  discountPromotion: number | null;
};

const ProductDetail: FC<ProductDetailProps> = ({
  productData,
  discountPromotion,
}) => {
  return (
    <div>
      <DiscountLabel discountPromotion={discountPromotion} />
      <img
        src={productData.image_transparent_url}
        width={'100%'}
        alt="products-image"
      />
    </div>
  );
};
