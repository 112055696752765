import React, { useState } from 'react';
import DeskNewSliderDesignPDD from './DeskNewSliderDesignPDD/DeskNewSliderDesignPDD';
import MobileNewSliderDesignPDD from './MobileNewSliderDesignPDD/MobileNewSliderDesignPDD';
import useViewport from '../../../Hooks/useViewport';
import { getRequest } from '../../../Helper/ApiClient';
import { INewComerWeekProduct } from '../Products/ProductsSlideshow/ProductsSlideshow';
import useNewcomerWeekHTMLConfig from '../../../Hooks/useNewcomerWeekHTMLConfig';
import ProductPages from '../Products/ProductPages/ProductPages';

const NewSliderDesignPD = () => {
  const viewport = useViewport(1200);
  const { data: productsData } = getRequest<INewComerWeekProduct[]>(
    `/api/onlineshop/newcomer-week/products`
  );
  const [layoutDesign] = useState<'old' | 'new'>('old');
  const [productsDataLength, setProductsDataLength] = useState(10);
  const [currentSection, setCurrentSection] = useState(0);
  const [scrollStates, setScrollStates] = useState<{ [key: number]: number }>(
    {}
  );

  // update the rendered elements on dom dynamically for better performance
  function handleUpdateProductsDataLength() {
    if (productsData && currentSection + 1 === productsDataLength - 2) {
      setProductsDataLength((prevVal) => {
        const newVal = Math.min(prevVal + 5, productsData.length);
        return newVal;
      });
    }
  }

  // update the url with the current product erp id on slide change
  // useEffect(() => {
  //   if (productsData && productsData.length > 0) {
  //     const newPid = productsData[currentSection].erp_id;
  //     window.history.replaceState(
  //       null,
  //       '',
  //       `/newcomer-week/products/${newPid}`
  //     );
  //   }
  // }, [currentSection, productsData]);

  return (
    <>
      {viewport === 'desktop' && layoutDesign === 'new' && (
        <DeskNewSliderDesignPDD
          productsData={productsData}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          scrollStates={scrollStates}
          setScrollStates={setScrollStates}
          productsDataLength={productsDataLength}
          handleUpdateProductsDataLength={handleUpdateProductsDataLength}
        />
      )}
      {viewport === 'mobile' && layoutDesign === 'new' && (
        <MobileNewSliderDesignPDD
          currentSection={currentSection}
          productsData={productsData}
          setCurrentSection={setCurrentSection}
        />
      )}

      {layoutDesign === 'old' && <ProductPages />}

      {/* design switcher button */}
      {/* {createPortal(
        <div
          style={{
            position: 'fixed',
            top: '10px',
            right: '180px',
            zIndex: 10,
            border: '1px solid #fff',
            padding: '5px',
            borderRadius: '5px',
            mixBlendMode: 'difference',
            pointerEvents: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="text-secondary d-none d-md-block"
        >
          <div
            onClick={() =>
              setLayoutDesign((prevVal) => {
                if (prevVal === 'new') return 'old';
                return 'new';
              })
            }
          >
            Switch Design
          </div>
        </div>,
        document.body
      )} */}
    </>
  );
};

export default NewSliderDesignPD;
