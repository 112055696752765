import * as React from 'react';
import { INewComerWeekBrands } from '../../../../../../../../Interface/Ncw';
import ButtonMutation from '../../../../../../../Abstracts/ButtonMutation/ButtonMutation';
import { formatCurrencyEur } from '../../../../../../../Helper/formatter';
import { postRequest } from '../../../../../../../Helper/ApiClient';
import { NcwBasketProduct } from '../../../../../Abstracts/NcwProductList/NcwProductList';
//@ts-ignore
import styles from './NcwBrandCategories.module.scss?module';
import { useNcw } from '../../../../../Context/NcwContext';
import { Col, Row } from 'react-bootstrap';
import NcwProductSize from '../../../../../Abstracts/NcwProductList/NcwProductSize/NcwProductSize';
// import { createPortal } from 'react-dom';
import { NcwIsElementNotSwipingAtom } from '../../../../../Atoms/NcwAtoms';
import { useSetAtom } from 'jotai';

export default function NcwBrandCategories({
  ncwBrand,
  activeBrand,
}: {
  ncwBrand: INewComerWeekBrands;
  activeBrand?: boolean;
}) {
  const [paPositions, setPaPositions] = React.useState<NcwBasketProduct[]>([]);
  const setScrollSnapEffect = useSetAtom(NcwIsElementNotSwipingAtom);
  const categorySectionRefs = React.useRef<{
    [key: string]: HTMLDivElement | null;
  }>({});

  const scrollToSection = (categoryId: string) => {
    const sectionRef = categorySectionRefs.current[categoryId];
    if (sectionRef) {
      sectionRef.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pa_id = Number(e.currentTarget.dataset.product_attribute_id);
    const qty = Number(e.currentTarget.value);
    const price = Number(e.currentTarget.dataset.price);
    if (qty > 0) {
      setPositionByPaIdQtyPrice(pa_id, qty, price);
    } else {
      removePositionByPaId(pa_id);
    }
  };

  const setPositionByPaIdQtyPrice = (_product_attribute_id, _qty, _price) => {
    const _paPositions = [...paPositions];
    const foundPaPositionProductAttribute = _paPositions.find(
      (_pa) => _pa.product_attribute_id === _product_attribute_id
    );
    if (foundPaPositionProductAttribute) {
      foundPaPositionProductAttribute.quantity = _qty;
      setPaPositions(_paPositions);
    } else {
      const currentPaPositionForBasket = [...paPositions];
      currentPaPositionForBasket.push({
        product_attribute_id: _product_attribute_id,
        quantity: _qty,
        price: _price,
      });
      setPaPositions(currentPaPositionForBasket);
    }
  };

  const removePositionByPaId = (productId: number) => {
    setPaPositions((prevPositions) =>
      prevPositions.filter(
        (position) => position.product_attribute_id !== productId
      )
    );
  };

  const getUnits = () => {
    const totalUnits = paPositions.reduce((acc, obj) => acc + obj.quantity, 0);
    return totalUnits ?? 0;
  };

  const getTotal = () => {
    const totalPrice = paPositions.reduce(
      (acc, obj) => acc + obj.price * obj.quantity,
      0
    );
    return formatCurrencyEur(totalPrice);
  };

  const addRefs = (element: HTMLInputElement | null) => {
    if (element) {
      const exists = refsArray.current.some((ref) => ref?.id === element.id);

      if (!exists) {
        refsArray.current.push(element);
      }
    }
  };

  const ncwContext = useNcw();
  const refsArray = React.useRef<(HTMLInputElement | null)[]>([]);

  const addToBasketMutation = postRequest(
    '/api/onlineshop/newcomer-week/basket/positions'
  );
  const handleClickAddAllToBasket = () => {
    let formData = new FormData();
    formData.append('positionsJson', JSON.stringify(paPositions));
    addToBasketMutation.mutate(formData, {
      onSuccess: () => {
        setTimeout(() => {
          setPaPositions([]);
          refsArray.current?.forEach((ref) => {
            if (ref) {
              ref.value = '';
            }
          });
          ncwContext.refetchNcwBasketHeader();
        }, 1000);
      },
    });
  };

  function handleCategoryClick(event: React.MouseEvent<HTMLButtonElement>) {
    const categoryId = event.currentTarget.dataset.categoryId!;

    setScrollSnapEffect(false);

    setTimeout(() => {
      scrollToSection(categoryId);
    }, 200);

    setTimeout(() => {
      setScrollSnapEffect(true);
    }, 1000);
  }

  const categories = ncwBrand.categories;
  return (
    <>
      <div className={`${styles.CategoriesContainer}`}>
        {Object.keys(categories).map((categoryId) => {
          return (
            <button
              key={categoryId}
              data-category-id={categoryId}
              onClick={handleCategoryClick}
              className={styles.CategoryItemBox}
            >
              {categories[categoryId].name}
            </button>
          );
        })}
      </div>
      <div className="d-flex overflow-hidden gap-md-4">
        <div aria-label="spacer" />
        <div className="d-flex flex-column w-100">
          <div aria-label="spacer" />
          {Object.keys(categories).map((categoryId) => {
            let categoryProducts = categories[categoryId];
            let componentRef = (el) => addRefs(el);
            return (
              <div
                key={categoryId}
                ref={(el) => (categorySectionRefs.current[categoryId] = el)}
              >
                {/* * /}
                <CategoryProducts
                  categoryProducts={categories[categoryId]}
                  componentRef={(el) => addRefs(el)}
                  onChange={onChange}
                />
                {/* */}
                <div className={'pt-5'}>
                  <h2>{categoryProducts.name}</h2>
                  <hr />
                </div>
                <div className="d-flex flex-column gap-4">
                  {categoryProducts.productSizes.map((productSizeData) => (
                    <React.Fragment key={productSizeData.id}>
                      <NcwProductSize
                        backgroundColor={ncwBrand.colorBackground}
                        color={ncwBrand.colorText}
                        isBrand
                        productSizeData={productSizeData}
                        componentRef={componentRef}
                        onChange={onChange}
                      />
                      <Row className="justify-content-end">
                        <Col md={12} lg={6}>
                          <table className={styles.AddToBasketTable}>
                            <tbody>
                              <tr>
                                <td></td>
                                <td>
                                  <ButtonMutation
                                    disabled={getUnits() === 0}
                                    onClick={handleClickAddAllToBasket}
                                    mutationResult={addToBasketMutation}
                                  >
                                    Add To Basket
                                  </ButtonMutation>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                        <Col sm={12}>
                          <hr />
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <div aria-label="spacer" />
      </div>
      {/* {activeBrand &&
        paPositions.length > 0 &&
        createPortal(
          <div
            className="d-flex flex-column gap-2 fs-4"
            style={{
              color: ncwBrand.colorText || 'white',
              position: 'fixed',
              bottom: 0,
              minHeight: '100px',
              width: '100vw',
              maxWidth: '100vw',
              zIndex: 1,
              textAlign: 'end',
              backgroundColor: 'black',
              paddingInline: '10px',
              whiteSpace: 'pre',
            }}
          >
            {paPositions.length > 0 && (
              <>
                {`UNITS: ${getUnits()} `}
                {'  '}
                {` TOTAL: ${getTotal()}`}
              </>
            )}
          </div>,
          document.body
        )} */}
    </>
  );
}
