import React from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { getRequest, postRequest } from '../../../Helper/ApiClient';
import { IProductListProduct } from '../../../Abstracts/ProductList/ProductList';
// @ts-ignore
import styles from './Favorites.module.scss?module';
import ProductImage from '../../../Abstracts/ProductList/Products/Product/ProductImage/ProductImage';
import ProductDetails from '../../../Abstracts/ProductList/Products/Product/ProductDetails/ProductDetails';
import { formatCurrencyEur } from '../../../Helper/formatter';
import ButtonMutation from '../../../Abstracts/ButtonMutation/ButtonMutation';
import { NcwBasketProduct } from '../Abstracts/NcwProductList/NcwProductList';
import { useNcw } from '../Context/NcwContext';

export default function Favorites() {
  const [paPositions, setPaPositions] = React.useState<NcwBasketProduct[]>([]);
  const favoritesListRequest = getRequest<IProductListProduct[]>(
    '/api/onlineshop/newcomer-week/favorite-products'
  );
  const favoriteProducts = favoritesListRequest?.data;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pa_id = Number(e.currentTarget.dataset.product_attribute_id);
    const qty = Number(e.currentTarget.value);
    const price = Number(e.currentTarget.dataset.price);
    if (qty > 0) {
      setPositionByPaIdQtyPrice(pa_id, qty, price);
    } else {
      removePositionByPaId(pa_id);
    }
  };

  const setPositionByPaIdQtyPrice = (_product_attribute_id, _qty, _price) => {
    const _paPositions = [...paPositions];
    const foundPaPositionProductAttribute = _paPositions.find(
      (_pa) => _pa.product_attribute_id === _product_attribute_id
    );
    if (foundPaPositionProductAttribute) {
      foundPaPositionProductAttribute.quantity = _qty;
      setPaPositions(_paPositions);
    } else {
      const currentPaPositionForBasket = [...paPositions];
      currentPaPositionForBasket.push({
        product_attribute_id: _product_attribute_id,
        quantity: _qty,
        price: _price,
      });
      setPaPositions(currentPaPositionForBasket);
    }
  };

  const removePositionByPaId = (productId: number) => {
    setPaPositions((prevPositions) =>
      prevPositions.filter(
        (position) => position.product_attribute_id !== productId
      )
    );
  };

  const getUnits = () => {
    const totalUnits = paPositions.reduce((acc, obj) => acc + obj.quantity, 0);
    return totalUnits ?? 0;
  };

  const getTotal = () => {
    const totalPrice = paPositions.reduce(
      (acc, obj) => acc + obj.price * obj.quantity,
      0
    );
    return totalPrice;
  };

  const addRefs = (element: HTMLInputElement | null) => {
    if (element) {
      const exists = refsArray.current.some((ref) => ref?.id === element.id);

      if (!exists) {
        refsArray.current.push(element);
      }
    }
  };

  const ncwContext = useNcw();
  const refsArray = React.useRef<(HTMLInputElement | null)[]>([]);
  const addToBasketMutation = postRequest(
    '/api/onlineshop/newcomer-week/basket/positions'
  );
  const handleClickAddAllToBasket = () => {
    let formData = new FormData();
    formData.append('positionsJson', JSON.stringify(paPositions));
    addToBasketMutation.mutate(formData, {
      onSuccess: () => {
        setTimeout(() => {
          setPaPositions([]);
          refsArray.current?.forEach((ref) => {
            if (ref) {
              ref.value = '';
            }
          });
          ncwContext.refetchNcwBasketHeader();
        }, 1000);
      },
    });
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md={7} className="my-4">
            <h1>Favorites</h1>
            {favoriteProducts?.map((product) => (
              <Row className="d-flex border-bottom" key={product.id}>
                <Col lg={3}>
                  <ProductImage product={product} />
                </Col>
                <Col lg={7}>
                  <Row className="my-3">
                    <Col>
                      <h4 className="fw-bolder mb-0">{product.name}</h4>
                      <div className="small fw-bolder">
                        {product.brand.name}
                      </div>
                      <ProductDetails {...product} />
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <div className="d-flex flex-column align-items-end justify-content-end my-3">
                    <h4>{formatCurrencyEur(product.price)}</h4>
                  </div>
                </Col>
                <Col>
                  {product.product_attributes.map((product_attribute) => (
                    <Row
                      className="mb-3"
                      style={{ backgroundColor: '#e1e3e7' }}
                      key={product_attribute.id}
                    >
                      <Col sm={9} xs={8}>
                        <div className="p-2 fw-bold">
                          {product_attribute.name}
                        </div>
                      </Col>
                      <Col sm={3} xs={4} className="pe-0">
                        <FormControl
                          onChange={onChange}
                          id={`${product_attribute.id}`}
                          ref={(el) => addRefs(el)}
                          className="text-center h-100"
                          min={1}
                          type={'text'}
                          inputMode={'numeric'}
                          data-price={product.price}
                          data-product_attribute_id={product_attribute.id}
                          style={{
                            border: 'none',
                          }}
                          placeholder={'-'}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
      <div className="fixed-bottom bg-white p-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={7} className="my-4 px-0">
              <div className="text-end">
                <span className="me-4">Units: {getUnits()}</span>
                <span className="me-4">
                  Total: {formatCurrencyEur(getTotal())}
                </span>
                <ButtonMutation
                  disabled={getUnits() === 0}
                  onClick={handleClickAddAllToBasket}
                  mutationResult={addToBasketMutation}
                >
                  Add all to basket
                </ButtonMutation>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
