import React, { FC, CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import {
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SliderPaginatorProps = {
  currentSection: number;
  productsDataLength: number;
  sliderHeight?: number;
  onNavigateNext?: () => void;
  onNavigatePrev?: () => void;
  direction?: 'horizontal' | 'vertical';
};

const SliderPaginator: FC<SliderPaginatorProps> = ({
  currentSection,
  productsDataLength,
  sliderHeight = window.innerHeight,
  onNavigateNext,
  onNavigatePrev,
  direction,
}) => {
  const totalSections = productsDataLength;
  const isHorizontal = direction === 'horizontal';

  const scrollToSection = (targetSection: number) => {
    if (onNavigateNext && onNavigatePrev) return;
    if (targetSection >= 0 && targetSection < totalSections) {
      window.scrollTo({
        top: targetSection * sliderHeight,
        behavior: 'smooth',
      });
    }
  };

  return createPortal(
    <div
      style={{
        position: 'fixed',
        top: isHorizontal ? '' : '50%',
        left: isHorizontal ? '50%' : '0%',
        bottom: isHorizontal ? '0%' : '',
        paddingLeft: isHorizontal ? '0' :'60px',
        zIndex: 2,
        translate: '-50% -50%',
        flexDirection: isHorizontal ? 'row' : 'column',
      }}
      className="d-flex gap-2 justify-content-center align-items-center"
    >
      {[
        {
          icon: isHorizontal ? faArrowLeft : faArrowUp,
          onClick: onNavigatePrev
            ? () => onNavigatePrev()
            : () => scrollToSection(currentSection - 1),
          disabled: currentSection === 0,
        },
        {
          content: (
            <>
              <div>
                <span>{currentSection + 1 < 10 && 0}</span>
                <span>{currentSection + 1}</span>
              </div>
              <span>/</span>
              <div>
                <span>{totalSections < 10 && 0}</span>
                <span>{totalSections}</span>
              </div>
            </>
          ),
        },
        {
          icon: isHorizontal ? faArrowRight : faArrowDown,
          onClick: onNavigateNext
            ? () => onNavigateNext()
            : () => scrollToSection(currentSection + 1),
          disabled: currentSection === totalSections - 1,
        },
      ].map((item, index) =>
        item.content ? (
          <div
            key={index}
            className="d-flex gap-2 justify-content-center align-items-center"
            style={{ ...commonButtonStyle(direction) }}
          >
            {item.content}
          </div>
        ) : (
          <button
            key={index}
            style={{
              cursor: item.disabled ? 'not-allowed' : 'pointer',
              opacity: item.disabled ? 0.5 : 1,
              backgroundColor: 'black',
              borderRadius: '50%',
              width: '38px',
              height: '38px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              border: 'none',
            }}
            onClick={item.onClick}
          >
            <FontAwesomeIcon icon={item.icon} />
          </button>
        )
      )}
    </div>,
    document.body
  );
};

const commonButtonStyle = (
  direction: SliderPaginatorProps['direction']
): CSSProperties => {
  return {
    flexDirection: direction === 'horizontal' ? 'row' : 'column',
    border: '2px solid #000',
    textAlign: 'center',
    zIndex: 3,
    color: '#fff',
    backgroundColor: 'black',
    padding: '5px 15px',
    borderRadius: '23px',
  };
};

export default SliderPaginator;
