import React, { forwardRef } from 'react';
import { Image } from 'react-bootstrap';

//@ts-ignore
// import styles from './ProductSlidePageImage.module.scss?module';
import { INewComerWeekProduct } from '../../../../ProductsSlideshow/ProductsSlideshow';
import SnapSection from '../../../../../Abstracts/SnapSection/SnapSection';
import DiscountLabel from '../../../../../Abstracts/DiscountLabel/DiscountLabel';
import ScrollDownAnimatedArrow from '../../../../../Abstracts/ScrollDownAnimatedArrow/ScrollDownAnimatedArrow';

type ProductSlidePageImage = {
  ncwProduct: INewComerWeekProduct;
};

const ProductSlidePageImage = forwardRef<HTMLDivElement, ProductSlidePageImage>(
  ({ ncwProduct }, ref) => {
    const productData = ncwProduct.product;

    return (
      <div ref={ref}>
        <SnapSection
          noOverflow
          className="d-flex align-items-center position-relative justify-content-center"
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundImage: ncwProduct.backgroundUrl
                ? `url("${ncwProduct.backgroundUrl}")`
                : undefined,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: `center`,
              width: '100%',
              height: 'calc(100vh - 112px)',
              zIndex: -1,
              transition: 'height 700ms ease-in-out',
            }}
          />
          <div className="d-flex justify-content-center align-items-center">
            <Image
              className={'w-100'}
              draggable={false}
              src={productData.image_transparent_url}
              style={{ maxWidth: '700px' }}
            />
          </div>
          <div className="h-100 d-flex justify-content-center align-items-end">
            {ncwProduct.discountPromotion && (
              <DiscountLabel discountPromotion={ncwProduct.discountPromotion} />
            )}
            <ScrollDownAnimatedArrow />
          </div>
        </SnapSection>
      </div>
    );
  }
);

export default ProductSlidePageImage;
