import * as React from 'react';
import { INewComerWeekProduct } from '../../Products/ProductsSlideshow/ProductsSlideshow';
// @ts-ignore
import styles from './NcwProductList.module.scss?module';
import { formatCurrencyEur } from '../../../../Helper/formatter';
import { postRequest } from '../../../../Helper/ApiClient';
import ButtonMutation from '../../../../Abstracts/ButtonMutation/ButtonMutation';
import NcwCircleXButton from '../../../../Abstracts/NcwCircleXButton/NcwCircleXButton';
import NcwProductSize from './NcwProductSize/NcwProductSize';
import { Col, Container, Row } from 'react-bootstrap';

export interface NcwBasketProduct {
  product_attribute_id: number;
  quantity: number;
  price: number;
}

export default function NcwProductList({
  ncwProduct,
  handleCloseActiveProduct,
  onAddToBasketSuccess,
  children,
}: {
  ncwProduct: INewComerWeekProduct;
  handleCloseActiveProduct?: () => void;
  onAddToBasketSuccess?: () => void;
  children?: React.ReactNode;
}) {
  const [paPositions, setPaPositions] = React.useState<NcwBasketProduct[]>([]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pa_id = Number(e.currentTarget.dataset.product_attribute_id);
    const qty = Number(e.currentTarget.value);
    const price = Number(e.currentTarget.dataset.price);
    if (qty > 0) {
      setPositionByPaIdQtyPrice(pa_id, qty, price);
    } else {
      removePositionByPaId(pa_id);
    }
  };

  const setPositionByPaIdQtyPrice = (_product_attribute_id, _qty, _price) => {
    const _paPositions = [...paPositions];
    const foundPaPositionProductAttribute = _paPositions.find(
      (_pa) => _pa.product_attribute_id === _product_attribute_id
    );
    if (foundPaPositionProductAttribute) {
      foundPaPositionProductAttribute.quantity = _qty;
      setPaPositions(_paPositions);
    } else {
      const currentPaPositionForBasket = [...paPositions];
      currentPaPositionForBasket.push({
        product_attribute_id: _product_attribute_id,
        quantity: _qty,
        price: _price,
      });
      setPaPositions(currentPaPositionForBasket);
    }
  };

  const removePositionByPaId = (productId: number) => {
    setPaPositions((prevPositions) =>
      prevPositions.filter(
        (position) => position.product_attribute_id !== productId
      )
    );
  };

  const getUnits = () => {
    const totalUnits = paPositions.reduce((acc, obj) => acc + obj.quantity, 0);
    return totalUnits ?? 0;
  };

  const getTotal = () => {
    const totalPrice = paPositions.reduce(
      (acc, obj) => acc + obj.price * obj.quantity,
      0
    );
    return formatCurrencyEur(totalPrice);
  };

  const addRefs = (element: HTMLInputElement | null) => {
    if (element) {
      const exists = refsArray.current.some((ref) => ref?.id === element.id);

      if (!exists) {
        refsArray.current.push(element);
      }
    }
  };

  const refsArray = React.useRef<(HTMLInputElement | null)[]>([]);

  const addToBasketMutation = postRequest(
    '/api/onlineshop/newcomer-week/basket/positions'
  );
  const handleClickAddAllToBasket = () => {
    let formData = new FormData();
    formData.append('positionsJson', JSON.stringify(paPositions));
    addToBasketMutation.mutate(formData, {
      onSuccess: () => {
        setTimeout(() => {
          setPaPositions([]);
          refsArray.current?.forEach((ref) => {
            if (ref) {
              ref.value = '';
            }
          });
          if (onAddToBasketSuccess) {
            onAddToBasketSuccess();
          }
        }, 1000);
      },
      onError: (error) => {
        // alert(error.message);
      },
    });
  };

  return (
    <div className={styles.ProductListContainer}>
      {handleCloseActiveProduct && (
        <div
          style={{
            width: '100%',
            minHeight: '100px',
          }}
        >
          <NcwCircleXButton
            color={ncwProduct.colorText || 'white'}
            onClick={handleCloseActiveProduct}
          />
        </div>
      )}

      <Container
        fluid
        className={'pt-md-0 d-flex flex-column gap-4 CustomScrollbar'}
        style={{
          color: ncwProduct.colorText || 'white',
          maxHeight: '100%', // todo @mohamad
          overflowY: 'auto', // todo @mohamad
          overflowX: 'hidden',
          overscrollBehavior: 'none',
        }}
      >
        <div className="d-lg-none" aria-label="spacer" />
        {ncwProduct.product.productSizes.map((productSizeData) => (
          <React.Fragment key={productSizeData.erp_id}>
            <NcwProductSize
              componentRef={(el) => addRefs(el)}
              onChange={onChange}
              productSizeData={productSizeData}
            />
            <Row className="justify-content-lg-end">
              <Col lg={6} className={'pt-4 d-flex'}>
                <ButtonMutation
                  disabled={getUnits() === 0}
                  onClick={handleClickAddAllToBasket}
                  mutationResult={addToBasketMutation}
                  // color="gray"
                  style={{
                    flex: 1,
                    minWidth: '200px',
                    height: '50px',
                    border: 'none',
                    fontWeight: '800 important',
                    display: 'flex',
                    padding: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1rem',
                  }}
                >
                  Add To Basket
                </ButtonMutation>
              </Col>
              <Row className={styles.SizeDividerRow}>
                <Col className={'py-3'}>
                  <hr />
                </Col>
              </Row>
            </Row>
          </React.Fragment>
        ))}
        {children}
        <div aria-label="spacer" />
      </Container>
    </div>
  );
}
