import { useEffect, useState } from 'react';

const useTypewriter = (text: string, speed = 10) => {
  const [displayText, setDisplayText] = useState('');
  const [isTypingEnd, setTypingEnd] = useState(false);

  useEffect(() => {
    if (!text) return;

    let i = 0;
    setDisplayText('');
    setTypingEnd(false);

    const timer = setInterval(() => {
      if (i < text.length) {
        setDisplayText((prev) => prev + text[i]);
        i++;
      } else {
        clearInterval(timer);
        setTypingEnd(true);
      }
    }, speed);

    return () => clearInterval(timer);
  }, [text, speed]);

  return [displayText, isTypingEnd];
};

export default useTypewriter;
