import { useRef, useEffect } from 'react';

interface UseSliderGestureProps {
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
}

export function useSliderGesture({
  onSwipeLeft,
  onSwipeRight,
}: UseSliderGestureProps) {
  const sliderRef = useRef<HTMLDivElement>(null);
  const startX = useRef(0);
  const startY = useRef(0);
  const isDragging = useRef(false);

  const handleGestureStart = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    startX.current = 'touches' in e ? e.touches[0].clientX : e.clientX;
    startY.current = 'touches' in e ? e.touches[0].clientY : e.clientY;
    isDragging.current = true;
  };

  const handleGestureMove = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    if (!isDragging.current) return;
    const currentY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const currentX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const diffX = currentX - startX.current;
    const diffY = currentY - startY.current;

    // verhindern dass scroll Y und scroll X gelichzeitig passieren
    if (diffY > 10 || diffY < -10) return;

    if (diffX > 20) {
      onSwipeLeft();
      handleGestureEnd();
    } else if (diffX < -20) {
      onSwipeRight();
      handleGestureEnd();
    }
  };

  const handleGestureEnd = () => {
    isDragging.current = false;
  };

  useEffect(() => {
    const sliderElement = sliderRef.current;

    if (!sliderElement) return;

    sliderElement?.addEventListener(
      'touchstart',
      handleGestureStart as unknown as EventListener
    );
    sliderElement?.addEventListener(
      'touchmove',
      handleGestureMove as unknown as EventListener
    );
    sliderElement?.addEventListener('touchend', handleGestureEnd);
    sliderElement?.addEventListener(
      'mousedown',
      handleGestureStart as unknown as EventListener
    );
    sliderElement?.addEventListener(
      'mousemove',
      handleGestureMove as unknown as EventListener
    );
    sliderElement?.addEventListener('mouseup', handleGestureEnd);
    sliderElement?.addEventListener('mouseleave', handleGestureEnd);

    return () => {
      sliderElement?.removeEventListener(
        'touchstart',
        handleGestureStart as unknown as EventListener
      );
      sliderElement?.removeEventListener(
        'touchmove',
        handleGestureMove as unknown as EventListener
      );
      sliderElement?.removeEventListener('touchend', handleGestureEnd);
      sliderElement?.removeEventListener(
        'mousedown',
        handleGestureStart as unknown as EventListener
      );
      sliderElement?.removeEventListener(
        'mousemove',
        handleGestureMove as unknown as EventListener
      );
      sliderElement?.removeEventListener('mouseup', handleGestureEnd);
      sliderElement?.removeEventListener('mouseleave', handleGestureEnd);
    };
  }, [handleGestureStart, handleGestureMove, handleGestureEnd]);

  return sliderRef;
}
