import React from 'react';
import NavigationButton from '../Components/NavigationButton/NavigationButton';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
//@ts-ignore
import styles from './Paginator.module.scss?module';

const Paginator = ({
  requestData,
  slideToNextItem,
  slideToPrevItem,
  currentSlideNum,
}) => {
  const isFirstItem = currentSlideNum === 0;
  const isLastItem = currentSlideNum === requestData?.length - 1;

  return (
    <div
      className={`${styles.PaginatorContainer} d-flex gap-4 align-items-center justify-content-center bg-primary py-2`}
    >
      <div
        style={{
          opacity: isFirstItem ? 0.5 : 1,
        }}
        onClick={() => {
          requestData && slideToPrevItem();
        }}
      >
        <NavigationButton size="xl" icon={faArrowLeft} />
      </div>

      <div className={styles.PaginationInfo}>
        <span>
          {currentSlideNum + 1 < 10 && 0} {currentSlideNum + 1}
        </span>
        <span>/</span>
        <span>
          {requestData?.length < 10 && 0} {requestData?.length}
        </span>
      </div>

      <div
        style={{ opacity: isLastItem ? 0.5 : 1 }}
        onClick={() => {
          requestData && slideToNextItem();
        }}
      >
        <NavigationButton size="xl" icon={faArrowRight} />
      </div>
    </div>
  );
};

export default Paginator;
